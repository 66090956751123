export const seo = {
  url: 'company/for-media',
  title: {
    pl: 'Dla Mediów',
    en: 'For Media',
  },
  desc: {
    pl: `To miejsce stworzone jest z myślą o przedstawicielach mediów, którzy poszukują informacji o Omida Sea And Air S.A. lub potrzebują szybkiego kontaktu z przedstawicielami Spółki.`,
    en: `This place is dedicated to media representatives looking for information about Omida Sea And Air S.A. or needing quick contact with Company representatives.`,
  },
  keywords: ['omida', 'sea and air', 'sea & air', 'media'],
}

export const intro = {
  title: {
    pl: 'Dla Mediów',
    en: 'For Media',
  },
  desc: {
    pl: `To miejsce stworzone jest z myślą o przedstawicielach mediów, którzy poszukują informacji o Omida Sea And Air S.A. lub potrzebują szybkiego kontaktu z przedstawicielami Spółki.`,
    en: `This place is dedicated to media representatives looking for information about Omida Sea And Air S.A. or needing quick contact with Company representatives.`,
  },
}

export const main = {
  title: {
    pl: 'Kontakt dla mediów',
    en: 'Contact for media',
  },
  html: `
  <p>
  <strong>Marketing</strong><br/>
  email: <a href="mailto:marketing@seaandair.pl">marketing@seaandair.pl</a><br/>
  tel: <a href="+48571291844">+48 571 291 844</a>
  </p>
  
  `,
}
